<template>
  <div class="grid">
    <div class="col-12">
      <div class="font-medium text-3xl text-900 mb-3">
        <span class="text-primary text-5xl">/</span> Devedores
      </div>
      <div class="text-500 mb-5">Listagem de devedores</div>

      <div class="card">
        <Toast />
        <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
          <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
            <Chips v-model="filters.filter" placeholder="Pesquisar" @add="loadRecords" @remove="loadRecords" />
          </div>
        </div>

        <DataTable dataKey="id" :value="records" :row-hover="true" responsiveLayout="stack" breakpoint="640px">
          <template #empty> Nenhum registro encontrado. </template>

          <template #loading> Carregando registros. Aguarde ... </template>

          <Column field="documento" header="Documento">
            <template #body="{ data }">
              {{ $filters.cpfCnpj(data.documento) }}
            </template>
          </Column>

          <Column field="nome" header="Nome"></Column>

          <Column field="updatedAt" header="Última atualização">
            <template #body="{ data }">
              {{ $filters.dateTime(data.updatedAt) }}
            </template>
          </Column>

          <Column bodyClass="text-right">
            <template #body="{ data }">
              <Button icon="pi pi-file" class="p-button-text p-button mr-2" v-tooltip.top="'Ficha do devedor'"
                @click="$router.push(`/devedores/${data.id}/record`)" />
              <Button icon="pi pi-history" class="p-button-text p-button mr-2" v-tooltip.top="'Histórico de alterações'"
                @click="$router.push(`/devedores/${data.documento}/history`)" />
            </template>
          </Column>
        </DataTable>

        <Paginator :rows="perPage" :totalRecords="totalItems" @page="onPage"
          template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
          currentPageReportTemplate="Página {currentPage} de {totalPages}">
        </Paginator>

        <Dialog v-model:visible="deleteRecordDialog" :style="{ width: '450px' }" header="Confirmar" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="record">Tem certeza que deseja excluir <b>{{ record.name }}</b>?</span>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="deleteRecordDialog = false" />
            <Button label="Excluir" icon="pi pi-trash" class="p-button-outlined p-button-danger"
              @click="deleteRecord" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import CrudList from '../../components/abstract/CrudList.vue'
import CrudService from '@/services/crud'

export default {
  extends: CrudList,
  data() {
    return {
      service: new CrudService('/admin/devedores'),
    }
  }
}
</script>

<style scoped lang="scss">

</style>
